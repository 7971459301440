import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { Roles } from 'models/User';

import { login, LoginParams } from 'store/modules/user/actions';

import { Props as ButtonProps } from 'components/layout/Button';

import Component from './component';

export interface Errors {
    email: string;
    password: string;
    recaptchaToken: string;
}
export interface Props extends ParentProps {
    actions: {
        login: (params: LoginParams) => Promise<any>;
    };
}
export interface ParentProps {
    title: string;
    input: {
        email: {
            label: string;
            placeholder: string;
        }
        password: {
            label: string;
            placeholder: string;
            title: string;
            buttonProps: ButtonProps;
        }
    }
    buttonProps: ButtonProps;
    roleType: Roles;
    onSuccess?: () => any;
    recaptchaToken?: string;
    isLoginPhone: boolean;
}

const mapDispatchToProps = (dispatch: Dispatch): object => {
    return {
        actions: {
            login: bindActionCreators(login, dispatch),
        },
    };
};

Component.defaultProps = {
    onSuccess: null,
    isLoginPhone: true,
};
export default connect(null, mapDispatchToProps)(Component);