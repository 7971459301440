import { FunctionComponent, useState } from 'react';

import Button, { ButtonTypes } from 'components/layout/Button';
import Form from 'components/layout/forms/FormAutoComplete';
import Input from 'components/layout/forms/Input';
import InputRow from 'components/layout/forms/InputRow';
import ReCAPTCHA from 'components/layout/ReCAPTCHA';

import { Errors, Props } from './index';
import StyledComponent from './styles';

const PublicLayoutFormsLoginForm: FunctionComponent<Props> = ({
    input,
    buttonProps,
    onSuccess,
    actions,
    roleType,
    isLoginPhone,
}) => {
    const [isPasswordVisible, setIsPasswordVisible]: [boolean, Function] = useState(false);
    const [recaptchaToken, setRecaptchaToken]: [string, Function] = useState(null);
    const [errorMessage, setErrorMessage]: [Errors, Function] = useState(null);

    return (
        <StyledComponent className="public-layout-forms-login-form">
            <Form
                submitAction={async (values) => {
                    try {
                        const response = await actions.login({
                            email: values?.email || '',
                            password: values?.password || '',
                            role: roleType || '',
                            recaptchaToken,
                        });

                        return response;
                    } catch (error) {
                        console.error(error);
                        setErrorMessage(error?.payload?.originalError?.data?.errors);
                        throw error;
                    }
                }}
                onSuccess={onSuccess}
            >
                {({ formik }) => {
                    return (
                        <>
                            <InputRow>
                                <Input
                                    name="email"
                                    type={isLoginPhone ? 'text' : 'email'}
                                    id="email-login"
                                    label={input?.email?.label}
                                    placeholder={input?.email?.placeholder}
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                    error={errorMessage?.email}
                                    autoComplete="on"
                                />
                            </InputRow>
                            <InputRow className="password-row">
                                <Input
                                    name="password"
                                    type={isPasswordVisible ? 'text' : 'password'}
                                    id="password-login"
                                    label={input?.password?.label}
                                    placeholder={input?.password?.placeholder}
                                    onChange={formik.handleChange}
                                    value={formik.values.password}
                                    autoComplete="on"
                                    decoratorRight={{
                                        children: (
                                            <a
                                                onClick={() => {
                                                    setIsPasswordVisible(!isPasswordVisible);
                                                }}
                                            >
                                                {input?.password?.title}
                                            </a>
                                        ),
                                    }}
                                    error={errorMessage?.password}
                                />
                                <Button
                                    className="password-reset-button"
                                    {...input?.password?.buttonProps}
                                />
                            </InputRow>

                            <div className="google-recaptcha">
                                <ReCAPTCHA
                                    onChange={(token: string) => setRecaptchaToken(token)}
                                    error={errorMessage?.recaptchaToken}
                                />
                            </div>

                            <Button
                                {...buttonProps}
                                className="log-in-button"
                                loading={formik.isSubmitting}
                                type={ButtonTypes.Submit}
                            />
                        </>
                    );
                }}
            </Form>
        </StyledComponent>
    );
};

export default PublicLayoutFormsLoginForm;