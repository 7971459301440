import Component from './component';

export enum ReCaptchaErrors {
    Refresh = 'timeout-or-duplicate',
    Again = 'Aby kontynuować, rozwiąż recaptcha ponownie.',
    MissingInput = 'Missing input response.',
    MissingInputPl = 'Aby kontynuować, rozwiąż recaptcha',
}

export interface Props {
    onChange: (token: string) => any;
    error: string;
}

Component.defaultProps = {};

export default Component;