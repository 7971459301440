import { FunctionComponent, useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import getConfig from 'next/config';

import ErrorMessage  from 'components/layout/ErrorMessage';

import { Props, ReCaptchaErrors } from './index';
import StyledComponent from './styles';

const LayoutReCAPTCHA: FunctionComponent<Props> = ({ onChange, error }) => {
    const [reCaptcha, setReCaptcha]: [any, Function] = useState(null);
    const [translateError, setTranslateError]: [string, Function] = useState(null);

    const { publicRuntimeConfig } = getConfig();

    useEffect(() => {
        setTranslateError(null);

        if (error === ReCaptchaErrors.MissingInput) setTranslateError(ReCaptchaErrors.MissingInputPl);

        if (error === ReCaptchaErrors.Refresh) {
            reCaptcha.reset();
            setTranslateError(ReCaptchaErrors.Again);
        }
    }, [reCaptcha, error]);

    return (
        <StyledComponent className="layout-re-captcha">
            <ReCAPTCHA
                ref={(captcha: Function) => {
                    setReCaptcha(captcha);
                }}
                sitekey={publicRuntimeConfig.RECAPTCHA_ID}
                onChange={onChange}
            />
            <ErrorMessage
                name="error"
                errorMessage={error && translateError}
            />
        </StyledComponent>
    );};

export default LayoutReCAPTCHA;