import { FunctionComponent } from 'react';

import ErrorIcon from 'theme/icons/error.svg';

import { Props } from './index';
import StyledComponent from './styles';

const LayoutFormsTextarea: FunctionComponent<Props> = ({ errorMessage } ) => (
    <StyledComponent
        className="layout-error-message"
    >
        {errorMessage && (
            <div className="error-container">
                <img
                    className="error-icon"
                    src={ErrorIcon}
                    alt=""
                    aria-hidden="true"
                    loading="lazy"
                />
                <div
                    className="error-message"
                    dangerouslySetInnerHTML={{ __html: errorMessage }}
                />
            </div>
        )}
    </StyledComponent>
);


export default LayoutFormsTextarea;