import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
    width: 100%;
    padding-top: 2em;

    input::-ms-reveal,
	input::-ms-clear {
		display: none;
	}

    .layout-forms-form {
        .title {
            font-size: 1.1em;
            text-align: left;
            padding-bottom: 0;
        }

        .password-row {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            .layout-forms-input {
                margin-right: 0;

                .layout-forms-input-wrapper {
                    .internal-wrapper {
                        .input-decorator {
                            text-decoration: none;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }

            .password-reset-button {
                align-self: flex-end;

                .button {
                    padding: .7em 0;

                    .button-content {
                        font-weight: 300;
                        font-size: .85em;
                    }

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        .google-recaptcha {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 2em;
        }
    }

    @media all and (max-width: ${vars.tabletL}) {
        .layout-forms-form {
            .password-row {
                .layout-button {
                    align-self: center;

                    .button {
                        .button-content {
                            font-size: 1.3em;
                            font-weight: 300;
                        }
                    }
                }
            }
            .log-in-button {
                &.layout-button {
                    .button {
                        .button-content {
                            display: block;
                        }
                    }
                }
            }

            .password-reset-button {

                a {
                    color: #000000;
                }

                .button {
                    .button-content {
                        text-decoration: underline;
                        color: black;
                    }
                }
            }
        }
    }

    @media all and (max-width: ${vars.mobileM}) {}
`;