import Component from './component';

export interface Props {
    children: any;
    className?: string;
    error?: boolean;
    errorMessage?: string;
}

Component.defaultProps = {
    children: null,
    className: '',
    error: false,
    errorMessage: null,
};

export default Component;