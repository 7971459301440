import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
	width: 100%;

	.error-container {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding: 0.5em 0 1em 0;

		.error-icon {
			margin-right: 0.5em;
			height: 1em;
			max-width: 100%;
		}

		.error-message {
			font-size: 0.9em;
			color: #ff0000;
		}
	}
`;
