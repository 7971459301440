import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default styled.div`
    .slick-slider {
        position: relative;
        z-index: 9;
        .slick-list {
            overflow: inherit;
            overflow-x: clip;
            .slick-track {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .slick-slide {
                    margin: 0 .5em;
                }
            }
        }
        .slick-arrow {
            z-index: 999;
            &.slick-prev {
                transition: transform .3s ease-in-out;
                &:before {
                    content: '<';
                    color: #000000;
                    font-size: 40px;
                }
                &:hover {
                    transform: translate(-50%, -50%);
                }
            }
            &.slick-next {
                transition: transform .3s ease-in-out;
                &:before {
                    content: '>';
                    color: #000000;
                    font-size: 40px;
                }
                &:hover {
                    transform: translate(50%, -50%);
                }
            }
        }
        .slick-dots {
            display: flex !important;
            justify-content: center;
            align-items: center;
            bottom: -3em;
            li {
                width: 2.5em;
                margin: 0;
                .icon {
                    filter: invert(92%) sepia(0%) saturate(1079%) hue-rotate(151deg) brightness(79%) contrast(81%);
                }

                &.slick-active {
                    .icon {
                        filter: invert(0%) sepia(83%) saturate(7432%) hue-rotate(15deg) brightness(89%) contrast(102%);
                    }
                }
            }
        }
    }
    @media all and (max-width: ${variables.mobileM}) {
        .slick-slider {
            .slick-list {
                overflow-x: visible;
                .slick-track {
                    .slick-slide {
                        opacity: .3;
                    }
                    .slick-active {
                        opacity: 1;
                    }
                }
            }
            .slick-arrow {
                &.slick-prev {
                    left: 7%;
                }
                &.slick-next {
                    right: 7%;
                }
            }
            .slick-dots {
                li {
                    width: 2.5em;
                    margin: .1em;
                }
            }
        }
    }
`;