import { connect } from 'formik';

import Component from './component';

export interface Props {
    errorMessage: string,
    name: string,
}

Component.defaultProps = {};

export default connect(Component);